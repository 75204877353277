<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Fornecedor"
            name="Fornecedor"
            placeholder="Fornecedor"
            v-model="notaEntrada.nomeFornecedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data de compra"
            name="Data de compra"
            placeholder="Data de compra"
            v-model="notaEntrada.dataCompraString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data de inclusão"
            name="Data de inclusão"
            placeholder="Data de inclusão"
            v-model="notaEntrada.dataInclusaoString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Valor"
            name="Valor"
            placeholder="Valor"
            v-model="notaEntrada.valor"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :search="search" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.quantidade }}</td>
              <td>{{ props.item.precoUnitario }}</td>
              <td>{{ props.item.totalProduto }}</td>
            </template>
          </v-data-table>
        </div>
        <div class="form-btn">
          <router-link :to="{ name: 'entrada' }" class="nounderline">
            <v-btn outline>Voltar</v-btn>
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { ServicoNotaEntrada } from "../../servicos/servicoNotaEntrada"
const servicoNotaEntrada = new ServicoNotaEntrada()
export default {
  data() {
    return {
      notaEntrada: "",
      produtos: [],
      headers: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "Valor unitário", align: "left", sortable: false, value: "precoUnitario" },
        { text: "Valor total", value: "totalProduto", align: "left", sortable: false }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoNotaEntrada.buscarPorId(this.id).then(res => {
        this.notaEntrada = res.data
        this.produtos = res.data.produtos
      })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
